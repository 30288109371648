.nav-item .nav-link{
  color: #BAB86C;
}
.logo img{
  width: 100px;
}
h2{
  padding: 20px;
}
p.home{
  color:#9f2f3c;
  font-size: x-large;
}
p.copy{
  position: fixed;
  bottom: -16px;
  padding: 19px;
  background: #58181F;
  width: 100%;
  font-size: 16px;
  color: #BAB86C;
}
form{ 
  margin:  0 auto;
  text-align: left;

}
fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow:  0px 0px 0px 0px #000;
          box-shadow:  0px 0px 0px 0px #000;
          background: #BAB86C;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
}
legend.scheduler-border {
  width:inherit; /* Or auto */
  padding:0 10px; /* To give a bit of padding on the left and right */
  border-bottom:none;
}
body{
  color: #9f2f3c;
  background: black;
  /* background-image: url("/POS_Fulltable5.jpg"); */
}
/* .css-hip9hq-MuiPaper-root-MuiAppBar-root,.MuiPaper-root{
  background-color: black !important;
} */